import React from 'react'
import Container from './container'

import styles from './hero.module.css'

function Hero ({ children, hex, palette }) {
  return (
    <div className={`${styles.root} ${styles[palette]} ${styles.company}`} style={{backgroundColor: hex}}>
      <Container>
        {children}
      </Container>
    </div>
  )
}

export default Hero
