import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Company from '../components/company'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query CompanyTemplateQuery($id: String!) {
    company: sanityCompany(id: { eq: $id }) {
      title
      _id
      _key
      slug {
        current
      }
      _rawExcerpt
      link
      logoColor {
        asset {
          _id
          url
        }
      }
      brandColor {
        hex
      }
      mainImage {
        asset {
          _id
          url
        }
      }
      sectors {
        _id
        title
      }
      stage {
        _id
        title
      }
    }
  }
`

const CompanyTemplate = props => {
  const { data, errors } = props
  const company = data && data.company
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {company && <SEO title={company.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {company && <Company {...company} />}
    </Layout>
  )
}

export default CompanyTemplate
