import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from './block-text'
import Container from './container'
import Hero from './hero-company'

import styles from './company.module.css'

class Company extends React.Component {
  render() {
    return (
      <>
        <Hero>
          {this.props.logo && this.props.logo.asset && (
            <img
              src={imageUrlFor(buildImageObj(this.props.logo))
                .url()}
              alt={this.props.title}
            />
          )}
          {!this.props.logo && this.props.logoColor && this.props.logoColor.asset && (
            <img
              src={imageUrlFor(buildImageObj(this.props.logoColor))
                .url()}
              alt={this.props.title}
              className={styles.fakeWhite}
            />
          )}
        </Hero>

        <div className={styles.wrapper}>
          <Container size="large">
              <section className={styles.longform}>
                  <div className={`${styles.mainContent} ${styles.singleColumn}`}>
                    <h2 className={`${styles.headline} ${styles.bullet}`}>{this.props.title}</h2>
                    {this.props._rawExcerpt && <BlockText blocks={this.props._rawExcerpt} />}
                    <a href={this.props.link} target="_blank">{this.props.link}</a>

                    <div className={styles.viewMore}>
                      <Link className={`${styles.button} ${styles.primary}`} to="/companies/">See our other companies</Link>
                    </div>
                  </div>
              </section>
          </Container>
        </div>
      </>
    )
  }
}

export default Company
